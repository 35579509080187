<template>
  <b-form-group
    label="Sigorta Polisoft Kullanıcı ID"
    label-for="insurance_pol_id"
  >
    <b-form-input
      id="insurance_pol_id"
      v-model="user.insurance_pol_id"
      placeholder="Kullanıcı Numarası"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'InsuranceIdCard',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    user() {
      return this.$store.getters['users/getUser']
    },
  },
}
</script>

<style scoped></style>
